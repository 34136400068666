<template>
    <div></div>
</template>
<script>
    import AppMixin from "../../mixins/AppMixin";
    import APIOrder from "../../api/APIOrder";

    export default {
        name: "QuickStart",
        data() {
            return {
                isLoading: false,
            }
        },
        mixins: [AppMixin],
        mounted() {
            const me = this;
            if (this.store === null) {
                this.$router.replace({name: '404'});
            }
            let orderType = this.$route.params.orderType;
            if (!this.validateOrderModes(orderType)) {
                return;
            }
            this.$store.dispatch('session/resetProgress')
                .then(() => {

                    me.$store.commit('session/location', '.ANYWHERE');
                    me.$store.commit('session/gpsLocation', {lat: 0, lng: 0});
                    me.$store.commit('session/orderType', orderType);
                    me.$store.commit('session/storeId', this.store.id);

                    me.$router.replace({name: 'fulfilment-time'});
                });
        },
        methods: {
            andQuitQuickStart() {
                this.$router.push({name: 'start-order'});
            },
            validateOrderModes(orderType) {
                // Is it even a valid order mode?
                if (!APIOrder.isValidOrderType(orderType)) {
                    this.appShowErrorDialog(this.$i18n.t('error.unknownOrderType', { orderType: orderType }), 'error', this.andQuitQuickStart);
                    return false;
                }
                // Is it available at this store?
                let isValidOrderModeForStore = false;
                switch (orderType) {
                    case APIOrder.Constants.OrderType.Collection:
                        isValidOrderModeForStore = this.store.collectAvailable;
                        break;
                    case APIOrder.Constants.OrderType.Curbside:
                        isValidOrderModeForStore = this.store.curbsideAvailable;
                        break;
                    case APIOrder.Constants.OrderType.Table:
                        isValidOrderModeForStore = this.store.tableAvailable;
                        break;
                    case APIOrder.Constants.OrderType.Delivery:
                        isValidOrderModeForStore = this.store.deliveryAvailable;
                        break;
                }
                if (!isValidOrderModeForStore) {
                    this.appShowErrorDialog(this.$i18n.t('error.unsupportedOrderType', { orderType: orderType }), 'error', this.andQuitQuickStart);
                    return false;
                }
                // is the store accepting orders
                if (!this.store.online) {
                    this.appShowErrorDialog(this.store.offlineReason ? this.store.offlineReason : this.i18n.t('message.defaultOfflineReason'), 'error', this.andQuitQuickStart);
                    return false;
                }
                return true;
            }
        },
        computed: {
            stores() {
                return this.$store.getters['app/stores'];
            },
            store() {
                let matchCode = this.$route.params.storeId.toUpperCase();
                for (let i in Object.keys(this.stores)) {
                    let key = Object.keys(this.stores)[i];
                    if (this.stores[key].code === matchCode) return this.stores[key];
                }
                return null;
            }
        }
    }
</script>
